"use client";

// *** Module *** //

import { ReactQueryStreamedHydration } from "@tanstack/react-query-next-experimental";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider } from "@/components/theme-provider";
import React, { useState } from "react";
import {
  QueryClient as Qc,
  QueryCache,
  QueryClientProvider,
} from "@tanstack/react-query";
import { SessionProvider } from "next-auth/react";

export const queryCache = new QueryCache();

export default function QueryProviderClient({
  children,
}: {
  children: React.ReactNode;
}) {
  const [client] = useState(
    new Qc({
      queryCache: queryCache,
      defaultOptions: {
        queries: {
          staleTime: 60 * 1000,
        },
      },
    })
  );

  return (
    <QueryClientProvider client={client}>
      <ThemeProvider
        attribute="class"
        defaultTheme="light"
        enableSystem
        disableTransitionOnChange
      >
        <SessionProvider>
          <ReactQueryDevtools
            initialIsOpen={false}
            position="right"
            buttonPosition="bottom-right"
          />
          <ReactQueryStreamedHydration>{children}</ReactQueryStreamedHydration>
        </SessionProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
